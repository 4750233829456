
<!-- Light Gallery Plugin Css -->
<link href="/assets/lightbox2/dist/css/lightbox.css" rel="stylesheet">
<div class="container pt-4">
    <section class="mt-5 wow fadeIn">
<div class="row">
  <!--First column-->
  <div class="col-md-12 mb-3">
    <h2 class="font-weight-bold my-3 text-center page-label-heading-green">Gallery</h2>
    <div id="mdb-lightbox-ui"></div>

    <!--Full width lightbox-->
    <div class="mdb-lightbox">

      <figure class="col-md-4">        
        <a href="assets/img/gallery/g1.jpg" data-lightbox="galleryShow" data-title="Gunvi Cotton Testing Laboratory">
            <img class="img-fluid z-depth-1" src="assets/img/gallery/g1.jpg">
        </a>
      </figure>

      <figure class="col-md-4">
        <a href="assets/img/gallery/g2.jpg" data-lightbox="galleryShow" data-title="Gunvi Cotton Testing Laboratory">
            <img class="img-fluid z-depth-1" src="assets/img/gallery/g2.jpg">
        </a>
      </figure>
      <figure class="col-md-4">
        <a href="assets/img/gallery/g5.jpg" data-lightbox="galleryShow" data-title="Gunvi Cotton Testing Laboratory">
            <img class="img-fluid z-depth-1" src="assets/img/gallery/g5.jpg">
        </a>
      </figure>

      <figure class="col-md-6">
        <a href="assets/img/gallery/g3.jpg" data-lightbox="galleryShow" data-title="Gunvi Cotton Testing Laboratory : Sample Opening Area">
            <img class="img-fluid z-depth-1" src="assets/img/gallery/g3.jpg">
        </a>
      </figure>
      <figure class="col-md-6">
        <a href="assets/img/gallery/g9.jpg" data-lightbox="galleryShow" data-title="Gunvi Cotton Testing Laboratory">
            <img class="img-fluid z-depth-1" src="assets/img/gallery/g9.jpg">
        </a>
      </figure>
      <figure class="col-md-4">
        <a href="assets/img/gallery/g6.jpg" data-lightbox="galleryShow" data-title="Gunvi Cotton Testing Laboratory">
            <img class="img-fluid z-depth-1" src="assets/img/gallery/g6.jpg">
        </a>
      </figure>
      <figure class="col-md-4">
        <a href="assets/img/gallery/g7.jpg" data-lightbox="galleryShow" data-title="Gunvi Cotton Testing Laboratory">
            <img class="img-fluid z-depth-1" src="assets/img/gallery/g7.jpg">
        </a>
      </figure>
      <figure class="col-md-4">
        <a href="assets/img/gallery/g8.jpg" data-lightbox="galleryShow" data-title="Gunvi Cotton Testing Laboratory : Conditioning Area">
            <img class="img-fluid z-depth-1" src="assets/img/gallery/g8.jpg">
        </a>
      </figure>
      
     
    </div>
    <!--/Full width lightbox-->

  </div>
  <!--/First column-->

</div>

    </section>
</div>
<script type="text/javascript" src="/assets/js/jquery-3.4.1.min.js"></script>
<script type="text/javascript" src="/assets/lightbox2/dist/js/lightbox.js"></script>





