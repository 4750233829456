<div class="container mt-3 pt-4">
    <section class="mt-5 wow fadeIn">
        <div class="row">
          <div class="col-md-12">       
            <h3 class="h3 mb-3 page-label-heading-green"><strong>Our Services</strong></h3>
            <hr>
        </div>
        <hr>
      </div>

      <div class="col-md-12">

      <!--Grid row-->
      <div class="row">

        <!--Grid column-->
        <div class="col-md-4 mb-4">
          <img src="assets/img/ipad.png" class="img-fluid z-depth-1-half" alt="Gunvi Cotton Testing Laboratory">
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-7 mb-4">

          <!-- Main heading -->
          <h3 class="h3 mb-3 page-label-heading-green-left"><strong>HVI TESTING WITH GUNVI</strong></h3>         
          <hr>
          <!-- Main heading -->
<p>We offer properly-calibrated testing laboratories and the independent handling of samples. These factors help to build trust with your customers and to provide high quality results in the final product.</p>
<p>We also ensure that we make the data immediately available to you in digital format, in summary reports arranged logically and clearly. These reports are structured in a way that ensures you can integrate them easily into your own applications. This makes it simpler for you, and your trading partners, to use the results and make informed commercial and technical judgments.</p>

        </div>
      </div>   
         <div class="row">
          <h3 class="h3 mb-3 page-label-heading-green-left"><strong>Advanced Technology</strong></h3>
          <p>Our well equipped laboratories verify cotton quality in terms of fiber length,strength,micronaire,color grade,short fiber content,trash distribution and other factors.The cotton classification and testing data we provide with these measurements gives the independent proof required to support the activities.</p>
          <h3 class="h3 mb-3 page-label-heading-green-left"><strong>Cotton Testing Process</strong></h3>
          <p>After receiving cotton in our laboratory,Our well trained staff inspect sample and open it as per the technical process,after opening the sample they were kept for conditioning for at least 8 hours as per the USDA law under the temperature 21±2°C with RH 65 %± 2%, conditioning is done by our automated conditioning Unit which maintain both temperature and RH at the same time.After that the sample is tested,four reading are taken of the sample and report is generated in the PDF format.</p>
         </div>
      <!--Grid row-->
    </div>
    </section>
  </div>
