<div class="container mt-3 pt-4">
    <section class="mt-5 wow fadeIn">      
        <div class="row">
          <div class="col-md-12">
            <h1 class="font-weight-bold my-3 text-center page-label-heading-green">We’re always here to hear from you</h1>
        </div>
      </div>
        <div class="col-md-12">
        <div class="row">
            <!-- Section: Contact v.3 -->
<section class="contact-section my-5" style="width:100% !important;">

    <!-- Form with header -->
    <div class="card">
  
      <!-- Grid row -->
      <div class="row">
  
        <!-- Grid column -->
        <div class="col-md-8" style="display: none;">
  
          <div class="card-body form">
  
            <!-- Header -->
            <h3 class="mt-4"><i class="fas fa-envelope pr-2"></i>Write to us:</h3>
  
            <!-- Grid row -->
            <div class="row">
  
              <!-- Grid column -->
              <div class="col-md-6">
                <div class="md-form mb-0">
                  <input type="text" id="form-contact-name" class="form-control">
                  <label for="form-contact-name" class="">Your name</label>
                </div>
              </div>
              <!-- Grid column -->
  
              <!-- Grid column -->
              <div class="col-md-6">
                <div class="md-form mb-0">
                  <input type="text" id="form-contact-email" class="form-control">
                  <label for="form-contact-email" class="">Your email</label>
                </div>
              </div>
              <!-- Grid column -->
  
            </div>
            <!-- Grid row -->
  
            <!-- Grid row -->
            <div class="row">
  
              <!-- Grid column -->
              <div class="col-md-6">
                <div class="md-form mb-0">
                  <input type="text" id="form-contact-phone" class="form-control">
                  <label for="form-contact-phone" class="">Your phone</label>
                </div>
              </div>
              <!-- Grid column -->
  
              <!-- Grid column -->
              <div class="col-md-6">
                <div class="md-form mb-0">
                  <input type="text" id="form-contact-company" class="form-control">
                  <label for="form-contact-company" class="">Your company</label>
                </div>
              </div>
              <!-- Grid column -->
  
            </div>
            <!-- Grid row -->
  
            <!-- Grid row -->
            <div class="row">
  
              <!-- Grid column -->
              <div class="col-md-12">
                <div class="md-form mb-0">
                  <textarea id="form-contact-message" class="form-control md-textarea" rows="3"></textarea>
                  <label for="form-contact-message">Your message</label>
                
                </div>
              </div>

             
              <!-- Grid column -->
  
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="md-form mb-0">
                        <div class="text-center text-md-left">
                            <a class="btn btn-indigo btn-md waves-effect waves-light">Send</a>
                          </div>
                    </div>
                </div>
            </div>
            <!-- Grid row -->
  
          </div>
  
        </div>
        <!-- Grid column -->
  
        <!-- Grid column -->
        <div class="col-md-12">
  
          <div class="card-body contact text-center h-100 white-text">
  
            <h3 class="my-4 pb-2">Contact information</h3>
            <ul class="text-lg-left list-unstyled ml-4">
              <li>
                <p><i class="fas fa-map-marker-alt pr-2"></i>51/1 Khandelwal Nagar G-1 King Tower,
                    Navlakha, Indore (M.P)- INDIA</p>
              </li>
              <li>
                <p><i class="fas fa-phone pr-2"></i>+91-0731-4021124, +91-0731-2405124</p>
              </li>
              <li>
                <p><i class="fas fa-envelope pr-2"></i>contact@example.com</p>
              </li>
            </ul>
            <hr class="hr-light my-4">
            <ul class="list-inline text-center list-unstyled" style="display: none;">
              <li class="list-inline-item">
                <a class="p-2 fa-lg tw-ic">
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a class="p-2 fa-lg li-ic">
                  <i class="fab fa-linkedin-in"> </i>
                </a>
              </li>
              <li class="list-inline-item">
                <a class="p-2 fa-lg ins-ic">
                  <i class="fab fa-instagram"> </i>
                </a>
              </li>
            </ul>
  
          </div>
  
        </div>
        <!-- Grid column -->
  
      </div>
      <!-- Grid row -->
  
    </div>
    <!-- Form with header -->
  
  </section>
  <!-- Section: Contact v.3 -->
        </div>
      </div>
    </section>
</div>