<header>

  <!-- Navbar -->
  <nav class="color-block navbar fixed-top navbar-expand-lg navbar-dark scrolling-navbar">
      <div class="container">

          <a class="navbar-brand" href="#">Gunvi Lab</a>
          <!-- <a class="navbar-brand avatar">
            <img src="assets/img/logo.jpg" class="rounded-circle z-depth-0" alt="avatar image">
        </a> -->

          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mr-auto">               
                  <li class="nav-item active">
                      <a class="nav-link" routerLink="home">
                          Home
                          <span class="sr-only">(current)</span>
                      </a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" routerLink="aboutUs">
                          About Us
                          <span class="sr-only">(current)</span>
                      </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" routerLink="ourServices">
                        Our Services
                        <span class="sr-only">(current)</span>
                    </a>
                </li>                 
                  <li class="nav-item">
                      <a class="nav-link" routerLink="ourTeam">
                          Our Team
                          <span class="sr-only">(current)</span>
                      </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" routerLink="gallery">
                        Gallery
                        <span class="sr-only">(current)</span>
                    </a>
                </li>
                 
                  <li class="nav-item">
                      <a class="nav-link" routerLink="contactUs">
                          Contact Us
                          <span class="sr-only">(current)</span>
                      </a>
                  </li>
              </ul>
              
          </div>

      </div>
  </nav>
  <!-- Navbar -->
</header>