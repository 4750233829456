<div class="container mt-3 pt-4">
    <section class="mt-5 wow fadeIn">
        <div class="row">
            <div class="col-md-12">
                  <!-- Main heading -->
        <h3 class="h3 mb-3 page-label-heading-green"><strong>Meet Our Team</strong></h3>         
        <hr>
            </div> 
        </div>

        <div class="row mb-5">
            <div class="col-md-4 mt-3">
                <div class="card testimonial-card mt-2 mb-3">

                    <!-- Background color -->
                    <div class="card-up aqua-gradient"></div>
            
                    <!-- Avatar -->
                    <div class="avatar mx-auto white">
                      <!-- <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20%2831%29.jpg" class="rounded-circle img-responsive" alt="woman avatar"> -->
                      <img src="assets/img/GUNVIFINAL.jpg" class="rounded-circle img-responsive" alt="woman avatar">
                    </div>
            
                    <!-- Content -->
                    <div class="card-body">
                      <!-- Name -->
                      <h4 class="card-title font-weight-bold">Name</h4>
                      <hr>
                      <h5 class="blue-text pb-2"><strong>Post or designation</strong></h5>
                      <!-- Quotation -->
                      <p></p>
                    </div>
            
                  </div>
            </div>

            
            <div class="col-md-4 mt-3">
                <div class="card testimonial-card mt-2 mb-3">

                    <!-- Background color -->
                    <div class="card-up aqua-gradient"></div>
            
                    <!-- Avatar -->
                    <div class="avatar mx-auto white">
                      <img src="assets/img/GUNVIFINAL.jpg" class="rounded-circle img-responsive" alt="woman avatar">
                    </div>
            
                    <!-- Content -->
                    <div class="card-body">
                        <h4 class="card-title font-weight-bold">Name</h4>
                        <hr>
                        <h5 class="blue-text pb-2"><strong>Post or designation</strong></h5>
                        <!-- Quotation -->
                        <p></p>
                    </div>
            
                  </div>
            </div>

            
            <div class="col-md-4 mt-3">
                <div class="card testimonial-card mt-2 mb-3">

                    <!-- Background color -->
                    <div class="card-up aqua-gradient"></div>
            
                    <!-- Avatar -->
                    <div class="avatar mx-auto white">
                      <img src="assets/img/GUNVIFINAL.jpg" class="rounded-circle img-responsive" alt="woman avatar">
                    </div>
            
                    <!-- Content -->
                    <div class="card-body">
                        <h4 class="card-title font-weight-bold">Name</h4>
                        <hr>
                        <h5 class="blue-text pb-2"><strong>Post or designation</strong></h5>
                        <!-- Quotation -->
                        <p></p>
                    </div>
            
                  </div>
            </div>

            
        </div>
    </section>
</div>