<!-- Footer -->
<footer class="page-footer font-small mdb-color pt-4">

    <!-- Footer Links -->
    <div class="container text-center text-md-left">
  
      <!-- Footer links -->
      <div class="row text-center text-md-left mt-3 pb-3">
          
        <!-- Grid column -->
        <div class="col-md-6 col-lg-6 col-xl-6 mx-auto mt-4">
          <h6 class="text-uppercase mb-4 font-weight-bold">Useful links</h6>
          <p>
            <a routerLink="home">Home</a>
          </p>
          <p>
            <a routerLink="aboutUs">About Us</a>
          </p>
          <p>
            <a routerLink="ourServices">Our Services</a>
          </p>         
          <p>
            <a routerLink="ourTeam">Our Team</a>
          </p>
          <p>
            <a routerLink="contactUs">Contact Us</a>
          </p>
        </div>
  
        <!-- Grid column -->
        <hr class="w-100 clearfix d-md-none">
  
        <!-- Grid column -->
        <div class="col-md-6 col-lg-6 col-xl-6 mx-auto mt-3">
          <h6 class="text-uppercase mb-4 font-weight-bold">Contact</h6>
          <p>
            <i class="fas fa-home mr-3"></i> 51/1 Khandelwal Nagar G-1 King Tower,
            Navlakha, Indore (M.P)- INDIA</p>
          <p>
            <i class="fas fa-envelope mr-3"></i> info@gmail.com</p>
          <p>
            <i class="fas fa-phone mr-3"></i> +91-0731-4021124, +91-0731-2405124</p>
        </div>
        <!-- Grid column -->
  
      </div>
      <!-- Footer links -->
  
      <hr>
  
      <!-- Grid row -->
      <div class="row d-flex align-items-center">
  
        <!-- Grid column -->
        <div class="col-md-7 col-lg-8">
  
          <!--Copyright-->
          <p class="text-center text-md-left">© 2020 Copyright:
            <a href="http://gunvicottontestinglab.in/">
              <strong> Gunvi Enterprises</strong>
            </a>
          </p>
  
        </div>
        <!-- Grid column -->
  
        <!-- Grid column -->
        <div class="col-md-5 col-lg-4 ml-lg-0">
  
          <!-- Social buttons -->
          <div class="text-center text-md-right">
            <ul class="list-unstyled list-inline text-center">
                <li class="list-inline-item">
                  <a class="btn-floating btn-fb mx-1 waves-effect waves-light">
                    <i class="fab fa-facebook-f"> </i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a class="btn-floating btn-tw mx-1 waves-effect waves-light">
                    <i class="fab fa-twitter"> </i>
                  </a>
                </li>                
                <li class="list-inline-item">
                  <a class="btn-floating btn-li mx-1 waves-effect waves-light">
                    <i class="fab fa-linkedin-in"> </i>
                  </a>
                </li>
                
              </ul>
          </div>
  
        </div>
        <!-- Grid column -->
  
      </div>
      <!-- Grid row -->
  
    </div>
    <!-- Footer Links -->
  
  </footer>
  <!-- Footer -->