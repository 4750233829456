
<!--Carousel Wrapper-->
<div id="carousel-example-2" class="carousel slide carousel-fade" data-ride="carousel">
    <!--Indicators-->
    <ol class="carousel-indicators">
      <li data-target="#carousel-example-2" data-slide-to="0" class="active"></li>
      <li data-target="#carousel-example-2" data-slide-to="1"></li>
      <li data-target="#carousel-example-2" data-slide-to="2"></li>
    </ol>
    <!--/.Indicators-->
    <!--Slides-->
    <div class="carousel-inner" role="listbox">
      <div class="carousel-item active">
        <div class="view">
          <img class="d-block w-100" src="/assets/img/slide/gunvi.jpg"
            alt="Gunvi Cotton Testing Laboratory">
          <div class="mask rgba-black-light"></div>
        </div>
        <div class="carousel-caption">
          <h3 class="h3-responsive">Gunvi Cotton Testing Laboratory</h3>
          <p></p>
        </div>
      </div>
      <div class="carousel-item">
        <!--Mask color-->
        <div class="view">
          <img class="d-block w-100" src="/assets/img/slide/gunvi.jpg"
            alt="Gunvi Cotton Testing Laboratory">
          <div class="mask rgba-black-strong"></div>
        </div>
        <div class="carousel-caption">
          <h3 class="h3-responsive">Gunvi Cotton Testing Laboratory</h3>
          <p></p>
        </div>
      </div>
      <div class="carousel-item">
        <!--Mask color-->
        <div class="view">
          <img class="d-block w-100" src="/assets/img/slide/gunvi.jpg"
            alt="Gunvi Cotton Testing Laboratory">
          <div class="mask rgba-black-slight"></div>
        </div>
        <div class="carousel-caption">
          <h3 class="h3-responsive">Gunvi Cotton Testing Laboratory</h3>
          <p></p>
        </div>
      </div>
    </div>
    <!--/.Slides-->
    <!--Controls-->
    <a class="carousel-control-prev" href="#carousel-example-2" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carousel-example-2" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
    <!--/.Controls-->
  </div>
  <!--/.Carousel Wrapper-->
  <div class="container">
    <section class="mt-5 wow fadeIn" style="visibility: visible; animation-name: fadeIn;">

      <!--Grid row-->
      <div class="row">

        <!--Grid column-->
        <div class="col-md-4 mb-4">
          <img src="assets/img/GUNVIFINAL.jpg" class="img-fluid z-depth-1-half" alt="Gunvi Cotton Testing Laboratory">
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-7 mb-4">

          <!-- Main heading -->
          <h3 class="h3 mb-3 page-label-heading-green"><strong>Gunvi Cotton Testing Laboratory</strong></h3>         
          <hr>
          <!-- Main heading -->
<p><strong> Gunvi </strong>was incorporated on<strong> 24th November 2011 </strong>as a Cotton testing laboratory in Indore (M.P)</p>
          <hr>

          <p>
            Since beginning Gunvi Enterprises philosophy was to provide quality service at an affordable price. The company has now grown to become one of renowned name in cotton testing in central area.
          </p>
<p>Gunvi’s high volume instrument (HVI) testing provides cotton growers, ginners, traders and spinning mills with accurate quality parameters for raw cotton.<strong> Our specialized laboratories verify cotton quality in terms of fiber length, strength, micronaire , color grade, short fiber content, trash distribution and other factors.</strong> The cotton classification and testing data we provide with these measurements gives you the independent proof you need to support your activities.</p>
        

        </div>
        <div class="col-md-12">
          <p>Some of our renowned major clients are LD Commodities, Reinheart, Trident Ltd, Spentex Ind., Satyam Spinners, India Trading, Bhadresh Trading Co.. AR cotex, DC Corporation, Tayal Group and many more. Recently We have start new branch at Aurangabad & Akola is under process</p>
          <p>Contact Gunvi to find out how our HVI testing services can deliver independently verified testing data in support of your cotton activities.</p>
        </div>
        <!--Grid column-->

      </div>
      <!--Grid row-->

    </section>
  </div>


