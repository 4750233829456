
    <div class="container mt-3 pt-4">
        <section class="mt-5 wow fadeIn">
            <div class="row">
                <div class="col-md-12">
                      <!-- Main heading -->
            <h3 class="h3 mb-3 page-label-heading-green"><strong>About Us</strong></h3>         
            <hr>
                </div>                
          <!--Grid column-->
          <div class="col-md-7 mb-4">
  
          
            <!-- Main heading -->
  <p><strong> Gunvi was incorporated on 24th November 2011 as a Cotton testing laboratory in Indore (M.P)</strong> </p>
           
            <p><strong>
              Since beginning Gunvi Enterprises philosophy was to provide quality service at an affordable price. The company has now grown to become one of renowned name in cotton testing in central area.
            </strong>
            </p>
  <p>Gunvi’s high volume instrument (HVI) testing provides cotton growers, ginners, traders and spinning mills with accurate quality parameters for raw cotton. Our specialized laboratories verify cotton quality in terms of fiber length, strength, micronaire , color grade, short fiber content, trash distribution and other factors. The cotton classification and testing data we provide with these measurements gives you the independent proof you need to support your activities.</p>
          
  
          </div>

          <div class="col-md-4 mb-4">
            <img src="assets/img/GUNVIFINAL.jpg" class="img-fluid z-depth-1-half" alt="Gunvi Cotton Testing Laboratory">
          </div>
          <div class="col-md-12">
            <p>Some of our renowned major clients are LD Commodities, Reinheart, Trident Ltd, Spentex Ind., Satyam Spinners, India Trading, Bhadresh Trading Co.. AR cotex, DC Corporation, Tayal Group and many more. Recently We have start new branch at Aurangabad & Akola is under process</p>
            <p>Contact Gunvi to find out how our HVI testing services can deliver independently verified testing data in support of your cotton activities.</p>
          </div>
          <div class="col-md-12">            
           <hr>
              <h3 class="h3 mb-3 page-label-heading-green-left"><strong>Our Assets</strong></h3>
           
          
            <ul>
              <li>Highly Advanced machinery</li>
              <li>Humidifier for conditioning</li>
              <li>Accuracy</li>
              <li>Punctuality</li>
              <li>Feasible Rates</li>
              <li>Pickup and Drop Facility</li>
              <li>Test report through SMS/Email/WhatsApp</li>
              <li>Proper Follow up</li>
              <li>Skilled and Well behaved Staff</li>
              <li>24 x 7 testing facility</li>
            </ul>
          </div>
          <!--Grid column-->
            </div>
        </section>
    </div>
