import { GalleryComponent } from './gallery/gallery.component';
import { OurteamComponent } from './ourteam/ourteam.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactusComponent } from './contactus/contactus.component';
import { CompanyserviceComponent } from './companyservice/companyservice.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {path:"",pathMatch:"full",redirectTo:"/home"},
  {path:"home",component:HomeComponent},
  {path:"aboutUs",component:AboutusComponent},
  {path:"ourServices",component:CompanyserviceComponent},
  {path:"ourTeam",component:OurteamComponent},
  {path:"contactUs",component:ContactusComponent},
  {path:"gallery",component:GalleryComponent},
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
